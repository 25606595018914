<template>
  <div>
    <q-dialog v-model="showDialog">
      <q-card style="width: 100%">
        <q-bar>
          <span class="form-title">{{ mode }} a team</span>
          <q-space />
          <q-icon v-close-popup name="far fa-times" />
        </q-bar>

        <q-form class="q-pa-md">
          <q-input
            filled
            v-model="name"
            label="Name"
            hint="A descriptive name for this team"
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Please type something',
            ]"
          />

          <br />
          <q-card-section :key="i" v-for="(role, i) in roles">
            <span class="text-h5">{{ role.label }}</span>

            <q-select
              v-model="modelMultiple"
              multiple
              :options="options"
              use-chips
              use-input
              fill-input
              stack-label
              input-debounce="2"
              @filter="filterFn"
              hint="An email address of member"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    No results
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </q-card-section>

          <q-card-actions align="center">
            <q-btn flat label="Cancel" v-close-popup color="secondary" />
            <q-btn flat label="Save" @click="save" color="primary" />
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>

    <section>
      <q-table
        :data="groups"
        :columns="columns"
        row-key="uuid"
        :pagination="{ rowsPerPage: 20 }"
        :dense="false"
        :filter="filter"
      >
        <template v-slot:top-right="props">
          <q-input
            outlined
            dense
            debounce="300"
            v-model="filter"
            placeholder="Search"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              dense
              round
              flat
              color="grey"
              @click="edit(props)"
              icon="fad fa-pencil"
            ></q-btn>
            <q-btn
              dense
              round
              flat
              color="grey"
              @click="remove(props)"
              icon="fad fa-trash"
            ></q-btn>
          </q-td>
        </template>
      </q-table>
    </section>
  </div>
</template>
<script>
import { API, clone } from "../tic";
import { QSpinnerGears } from "quasar";

export default {
  name: "admin-teams",
  props: ["showAdd"],
  watch: {
    showAdd() {
      this.add();
    },
  },

  data() {
    return {
      modelMultiple: [],
      options: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      mode: null, //edit or add
      showDialog: false,
      roles: [],
      preset: "",
      filter: "",
      showEdit: false,
      archetypes: [],
      actions: [],
      cleanPerms: {},
      perms: {},
      permset: {},
      name: "",
      code: "",
      groups: [],
      columns: [
        {
          name: "name",
          label: "name",
          field: (row) => row.name,
          align: "left",
        },
        // { name: 'code', label:'code', field: row => row.code, align:'left' },
        { name: "actions", label: "action", field: "actions", align: "right" },
      ],
    };
  },

  // https://www.raymondcamden.com/2019/08/12/working-with-the-keyboard-in-your-vue-app
  created() {
    window.addEventListener("keydown", this.doCommand);
  },

  destroyed() {
    window.removeEventListener("keydown", this.doCommand);
  },

  mounted() {
    // toggle loading
    this.$q.loading.show();

    // get all groups
    this.load();

    // get permissions
    API.get("/system/group-permissions").then((r) => {
      this.archetypes = r.data.data.archetypes;
      this.actions = r.data.data.actions;

      // get all actions
      for (let p in this.actions) {
        this.permset[this.actions[p].code] = false;
      }

      // get all content types
      for (let a in this.archetypes) {
        this.perms[this.archetypes[a].code] = clone(this.permset);
      }

      this.cleanPerms = clone(this.perms);
      this.$q.loading.hide();
    });
  },

  methods: {
    filterFn(val, update, abort) {
      if (val.length < 2) {
        abort();
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        API.get(`/system/member-search`, { params: { search: needle } }).then(
          (r) => {
            console.log("r: ", r.data.data);
            // this.options = r.data.data.filter(v => v.email.toLowerCase().indexOf(needle) > -1)
            this.options = r.data.data.map((e) => e.email);
          }
        );

        console.log("val: ", val);
      });
    },

    add() {
      this.mode = "add";
      this.showDialog = true;
    },

    doCommand(e) {
      if (e.keyCode === 27) {
        this.cancel();
      }
    },

    load() {
      // load known Roles
      API.get("/system/roles").then((r) => {
        this.roles = r.data.data.roles.filter((e) => e.teams);
      });

      // load the groups data
      API.get("/system/groups").then((r) => {
        this.groups = r.data.data;
        for (let g of this.groups) {
          if (g.perms) {
            g.perms = JSON.parse(g.perms);
          }
        }
      });
    },

    cancel() {
      this.showEdit = false;
      this.$emit("hide");
    },

    edit(props) {
      console.log("edit :", props);
      this.mode = "edit";

      // console.log("edit-group ", props)
      // console.log("groups ", this.groups)
      // let record = this.groups.find(e => e.uuid===props.key)

      // console.log("XXX>> ", record.perms ? true:false)

      // console.log(">>> ", record.perms)
      // console.log("found record: ", record)
      // this.code = record.code
      // this.name = record.name
      // this.uuid = record.uuid
      // //this.perms = record.perms ? record.perms : clone(this.cleanPerms)
      // console.log("set these perms: ", record.perms)
      // this.perms = clone(record.perms)

      // // clone(this.cleanPerms)
      // console.log("init perms: ",record.perms )
      // this.showEdit=true
    },

    remove(props) {
      this.$q
        .dialog({
          title: '<i class="fas fa-trash"></i>&nbsp;Confirm',
          message: `Really delete this item?<br><b>${props.row.name}</b>`,
          cancel: true,
          persistent: true,
          html: true,
        })
        .onOk(() => {
          API.delete(`/system/groups/${props.key}`).then((r) => {
            // reload data after delete
            this.load();
          });
        });
    },

    save() {
      // save the data, then clean local copy
      this.$q.loading.show({
        spinner: QSpinnerGears,
        spinnerColor: "green",
        messageColor: "green",
        backgroundColor: "lightgrey",
      });

      API.post("/system/group", {
        code: this.code,
        name: this.name,
        perms: this.perms,
        uuid: this.uuid ? this.uuid : null,
      }).then((r) => {
        this.$q.loading.hide();
        this.$emit("hide");
        this.showEdit = false;
        this.code = "";
        this.name = "";
        this.uuid = null;
        this.perms = clone(this.cleanPerms);
        this.load();
      });
    },

    // get the value for the checkbox, deal with missing entries
    getCheckboxValue(type, perm) {
      try {
        let val = this.perms[type][perm];
      } catch (err) {
        if (this.perms[type] === undefined) {
          this.perms[type] = {};
          this.perms[type][perm] = false;
        }
      }

      // do not return undefined
      return this.perms[type][perm] ? this.perms[type][perm] : false;
    },

    checkboxClick(value, type, perm) {
      this.perms[type][perm] = !this.perms[type][perm];
      // force an update, Vue is not smart enough to detect the mutation above.
      this.$forceUpdate();
    },
  },
};
</script>
<style>
.permissions td:nth-child(even) {
  background-color: #eee;
}
</style>